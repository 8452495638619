import React from "react";

import Layout from "../components/layout";
import { Breadcrumbs } from "../components/styleguide";

const StaticPage = ({ pageContext }) => {
  const { common, breadcrumbs, enableCookie } = pageContext;
  const title = common.lang === "pl" ? "Dziękujemy" : "Thank you";
  const desc =
    common.lang === "pl"
      ? "Dziękujemy za wiadomość. Skontaktujemy się z Tobą wkrótce."
      : "Thank you for your message. We will contact with you soon.";
      
  return (
    <Layout
      data={common}
      title={title}
      description={""}
      enableCookie={enableCookie}>
      <div className="static-page-content">
        <div className="main-container">
          <Breadcrumbs data={breadcrumbs} lang={common.lang} />
          <header>
            <h1>{title}</h1>
          </header>
          <div className="markdown-content">
            <p>{desc}</p>
          </div>
        </div>
      </div>
    </Layout>
  );
};

export default StaticPage;
